@import "~@ui/styles/tools";

.shadow-container {
    box-shadow: 0 10px 28px 0 #88888814;
    border-radius: 24px;
}

.chart {
    box-shadow: 0 0 4px 0 #0000000A;
    padding: 40px;
    min-height: 350px;

    @include media-tablet {
        padding: 10px;
    }

    &.chart--npl15 {
        padding-right: 2px;
    }

    @include media-mobile {
        min-height: 300px;
    }
}

.chart--without-padding {
    padding: 0;
}

.name {
    color: #A5A6A6;
    margin-bottom: 30px;
}

.description {
    color: #A5A6A6;
    font-size: 18px;
    font-weight: 300;
    margin-top: 32px;
}

@include media-mobile {
    .description {
        font-size: 14px;
        line-height: 18px;
    }
}