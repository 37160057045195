@import "~@ui/styles/tools";
@import "src/styles/variables";

@mixin medium-breakpoint {
    @media screen and (max-width: 1650px) {
        @content;
    }
}

@mixin small-breakpoint {
    @media screen and (max-width: 1280px) {
        @content;
    }
}

.event {
    display: flex;
    flex-direction: column;

    &:nth-of-type(1) {
        order: 0;
        grid-column: 1 / 3;
    }
    &:nth-of-type(2) {
        order: 1;
        grid-column: 4 / 6;
    }
    &:nth-of-type(3) {
        order: 2;
        grid-column: 7 / 9;
    }
    &:nth-of-type(4) {
        order: 3;
        grid-column: 10 / 12;
    }
    &:nth-of-type(5) {
        order: 6;
        grid-column: 9 / 12;
    }
    &:nth-of-type(6) {
        order: 5;
        grid-column: 5 / 8;
    }
    &:nth-of-type(7) {
        order: 4;
        grid-column: 1 / 4;
    }
}

.line-container {
    display: none;
    margin-top: auto;
    margin-bottom: 8px;
}

.year {
    font-size: 24px;
    line-height: 28px;
    font-weight: 400;
    color: #a2a8ad;

    margin-bottom: 20px;

    &--last {
        color: $color-primary;
    }
}

.title-wrapper {
    display: flex;
}

.title {
    font-size: 72px;
    line-height: 86px;
    font-weight: 400;

    margin-bottom: 16px;

    & > span:not(:first-child) {
        font-size: 24px;
        line-height: 28px;
    }
}

.title-description {
    font-size: 20px;
    line-height: 24px;
    color: $color-primary;
}

.description {
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: #a2a8ad;
}

@include medium-breakpoint {
    .title {
        font-size: 48px;
        line-height: 58px;
    }
}

@include small-breakpoint {
    .year {
        font-size: 20px;
        line-height: 24px;
    }

    .description {
        font-size: 16px;
        line-height: 19px;
    }
}

@include media-tablet {
    .event {
        user-select: none;
        height: 100%;
    }

    .line-container {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .event-line {
        display: block;
        height: 2px;
        width: 100%;
        background-color: #dfdfdf;

        transform: scale(1.2, 1);
        transform-origin: left;

        &--tail {
            transform: scale(1, 1);
            mask-image: linear-gradient(to right, #000 20%, transparent 100%);
        }
    }

    .history-point {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background-color: #dfdfdf
    }

    .description {
        max-width: 20ch;

        margin-bottom: 26px;
    }
}