@import "~@ui/styles/tools";

.section {
    padding: 4% 8%;

    @include media-tablet {
        padding: 4% 16px;
    }
}

.container {
    display: grid;
    margin-top: 50px;
    background: #FFF;
    padding: 32px;
    border-radius: 12px;
    grid-template-areas: 
        "revenue goal"
        "footer -";
    grid-template-columns: 1fr 1fr;
}

.title {
    color: $color-title-active;
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
}

.description {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #78797A;
}

.revenue {
    grid-area: revenue;
    border-right: 1px dashed #D9D9D9;
}

.goal {
    grid-area: goal;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #C1C1C1;
    padding-left: 32px;
}

.goal__chart {
    margin-top: 30px;

    @include media-tablet {
        margin-top: 10px;
    }

    @include media-mobile {
        margin-top: 24px;
    }
}

.footer {
    grid-area: footer;
    padding-top: 56px;
    color: #78797A;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    sup {
        color: $color-primary;
        margin-right: 2px;
    }
}

@include media-tablet {
    .revenue {
        padding-right: 32px;
    }

    .goal {
        border-bottom: none;
    }

    .footer {
        border-right: 1px dashed #D9D9D9;
    }
}

@include media-tablet-portrait {
    .container {
        grid-template-areas: 
            "revenue"
            "footer"
            "goal";
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto 1fr;
    }

    .revenue {
        border-right: none;
    }

    .goal {
        border-bottom: none;
        padding-left: 0;
        border-top: 1px dashed #D9D9D9;
        padding-top: 24px;
    }

    .footer {
        margin-bottom: 24px;
    }
}

@include media-mobile {
    .container {
        padding: 24px 20px;
    }
    
    .revenue {
        padding-right: 0;
    }

    .footer {
        border-right: none;
    }
}